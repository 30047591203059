import { Title } from '@solidjs/meta';
import { Avatar } from '@troon/ui';
import { StyleCard } from '../_style-card';

export default function AvatarPage() {
	return (
		<>
			<Title>Avatar | Components | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Avatar</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="Avatar">
					<div class="flex flex-row flex-wrap justify-center gap-2">
						<Avatar firstName="Paul" lastName="Armstrong" class="size-16 text-brand" />
						<Avatar firstName="Zach" lastName="Baron" class="size-16 rounded-full bg-brand text-brand-100" />
						<Avatar class="size-16 text-brand" />
						<Avatar class="size-16 text-brand-100" />
					</div>
					<div class="flex flex-row flex-wrap justify-center gap-2">
						<Avatar firstName="Paul" lastName="Armstrong" class="size-12 text-brand" />
						<Avatar firstName="Zach" lastName="Baron" class="size-12 rounded-full bg-brand text-brand-100" />
						<Avatar class="size-12 text-brand" />
						<Avatar class="size-12 text-brand-100" />
					</div>
					<div class="flex flex-row flex-wrap justify-center gap-2">
						<Avatar firstName="Paul" lastName="Armstrong" class="size-8 text-brand" />
						<Avatar firstName="Zach" lastName="Baron" class="size-8 rounded-full bg-brand text-brand-100" />
						<Avatar class="size-8 text-brand" />
						<Avatar class="size-8 text-brand-100" />
					</div>
				</StyleCard>
			</div>
		</>
	);
}
